import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

function useGA() {
  const location = useLocation();

  if (process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID);
  }

  useEffect(() => {
    if (process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location.pathname]);
}

export default useGA;
