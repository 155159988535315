/* eslint-disable import/no-unresolved */
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';

import useGA from 'hooks/useGA';
import { Helmet } from 'react-helmet';

import pageTitles from './pageTitles.json';

import bootcampMain from './variants/bootcamp/main.json';
import bootcampEarly from './variants/bootcamp/early.json';
import bootcampUpgrade from './variants/bootcamp/upgrade.json';
import bootcampEvergreen from './variants/bootcamp/evergreen.json';

import ad1 from './variants/bootcamp/ad1.json';
import ad2 from './variants/bootcamp/ad2.json';
import ad3 from './variants/bootcamp/ad3.json';
import ad4 from './variants/bootcamp/ad4.json';
import ad5 from './variants/bootcamp/ad5.json';

import mentorshipApplication from './variants/mentorship/application.json';
import mentorshipApplicationSuccess from './variants/mentorship/success.json';

import javaScriptLaunch from './variants/javascript/launch.json';

const Bootcamp = lazy(() => import('views/bootcamp'));
const BootcampStepper = lazy(() => import('views/bootcamp_stepper'));
const JavaScriptRegister = lazy(() => import('views/javascript_register'));
const JavaScriptStepper = lazy(() => import('views/javascript_stepper'));
const JavaScriptSales = lazy(() => import('views/javascript_sales'));
const NFT = lazy(() => import('views/nft'));
const Flashloan = lazy(() => import('views/flashloan'));
const TradingBot = lazy(() => import('views/tradingbot'));
const Airdrop = lazy(() => import('views/airdrop'));
const Mentorship = lazy(() => import('views/mentorship'));
const Sales = lazy(() => import('views/sales'));
const VideoStepper = lazy(() => import('views/videostepper'));

function App() {
  useGA();
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    if (location.pathname !== pageTitle) {
      setPageTitle(pageTitles[location.pathname]);
    }
  }, [location.pathname]);
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Suspense fallback={<div />}>
        <Routes>
          {/* Bootcamp Main Launch */}
          <Route path="/bootcamp-f7edb117" element={<Bootcamp variant={bootcampMain} />} />

          {/* Bootcamp Main Launch & Bootcamp Upgrade - Video Stepper */}
          <Route path="/bootcamp-2462bf4f" element={<BootcampStepper variant={bootcampMain.training_videos} />} />
          <Route path="/bootcamp-31d47c4c" element={<BootcampStepper variant={bootcampMain.training_videos} />} />
          <Route path="/bootcamp-8e8d0124" element={<BootcampStepper variant={bootcampMain.training_videos} />} />

          {/* Bootcamp Early Access - $499 / $749 / $999 */}
          <Route path="/bootcamp-a08dcdd2" element={<Bootcamp variant={bootcampEarly.low} />} />
          <Route path="/bootcamp-49ba8569" element={<Bootcamp variant={bootcampEarly.medium} />} />
          <Route path="/bootcamp-dfcc5a99" element={<Bootcamp variant={bootcampEarly.high} />} />

          {/* Bootcamp Early Access - Video Stepper */}
          <Route path="/bootcamp-678d10ef" element={<BootcampStepper variant={bootcampEarly.training_videos} />} />
          <Route path="/bootcamp-1282a1fe" element={<BootcampStepper variant={bootcampEarly.training_videos} />} />
          <Route path="/bootcamp-073dd36a" element={<BootcampStepper variant={bootcampEarly.training_videos} />} />

          {/* Bootcamp Upgrade */}
          <Route path="/bootcamp-rby63a21" element={<Bootcamp variant={bootcampUpgrade} />} />

          {/* Bootcamp Evergreen */}
          <Route path="/bootcamp-47d807bb" element={<Bootcamp variant={bootcampEvergreen} />} />

          {/* Bootcamp Evergreen - Video Stepper */}
          <Route path="/bootcamp-9245149b" element={<BootcampStepper variant={bootcampEvergreen.training_videos} />} />
          <Route path="/bootcamp-2c61ccca" element={<BootcampStepper variant={bootcampEvergreen.training_videos} />} />
          <Route path="/bootcamp-8e4c800d" element={<BootcampStepper variant={bootcampEvergreen.training_videos} />} />

          {/* Retargeting Ads */}
          <Route path="/bootcamp-58e918cc" element={<Bootcamp variant={ad1} />} />
          <Route path="/bootcamp-69f020dd" element={<Bootcamp variant={ad2} />} />
          <Route path="/bootcamp-70g131ee" element={<Bootcamp variant={ad3} />} />
          <Route path="/bootcamp-81h242ff" element={<Bootcamp variant={ad4} />} />
          <Route path="/bootcamp-92i353gg" element={<Bootcamp variant={ad5} />} />

          {/* JavaScript Course Opt-in */}
          <Route path="/javascript" element={<JavaScriptRegister success={false} />} />
          <Route path="/javascript-success" element={<JavaScriptRegister success />} />

          {/* JavaScript Video Stepper */}
          <Route path="/javascript-fr6hna18" element={<JavaScriptStepper />} />
          <Route path="/javascript-lk8q1vm0" element={<JavaScriptStepper />} />
          <Route path="/javascript-ty8c2n10" element={<JavaScriptStepper />} />

          {/* JavaScript Sales Page */}
          <Route path="/javascript-gb8q2p2f" element={<JavaScriptSales variant={javaScriptLaunch} />} />

          {/* NFT Masterclass */}
          <Route path="/nft-masterclass-k1bc12k40" element={<NFT />} />

          {/* Flashloan Masterclass */}
          <Route path="/flash-k1ab3q9" element={<Flashloan />} />

          {/* Trading Bot Masterclass */}
          <Route path="/trading-f24ac45" element={<TradingBot />} />

          {/* Airdrop Masterclass */}
          <Route path="/airdrop-g97xj2f" element={<Airdrop />} />

          {/* Mentorship */}
          <Route path="/mentorship-application-18vkj3k" element={<Mentorship variant={mentorshipApplication.first} />} />
          <Route path="/mentorship-application-13lrpq9" element={<Mentorship variant={mentorshipApplication.second} />} />
          <Route path="/mentorship-application-4rg89za" element={<Mentorship variant={mentorshipApplication.third} />} />
          <Route path="/mentorship-application-90pwr50" element={<Mentorship variant={mentorshipApplication.forth} />} />
          <Route path="/mentorship-application-p1v43ou" element={<Mentorship variant={mentorshipApplication.fifth} />} />

          {/* Mentorship Application Success Video */}
          <Route path="/mentorship-application-success-10vokd" element={<Mentorship variant={mentorshipApplicationSuccess.video} />} />

          {/* Mentorship Application Success No Video */}
          <Route path="/mentorship-application-success-67hn2d" element={<Mentorship variant={mentorshipApplicationSuccess.default} />} />

          {/* BlockSight */}
          <Route path="/bs-launch-k2ab92x" element={<Sales />} />
          <Route path="/bs-day-1ph4kd5" element={<VideoStepper />} />
          <Route path="/bs-day-209fk21j" element={<VideoStepper />} />
          <Route path="/bs-day-1kzh46ia" element={<VideoStepper />} />
          <Route path="/bs-day-1kzh46ib" element={<VideoStepper />} />

          {/* DEFAULT LINK TO SHOW ON ANY OTHER ROUTES */}
          <Route path="*" element={<Navigate to="/flash-k1ab3q9" />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
